<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Data Formasi Jabatan</p>
          </v-card-actions>
          <v-divider />
          <v-card-actions>
            <v-btn
              text
              class="text-capitalize caption headline-color"
              color="primary"
              @click="visible = true"
              >Buat Formasi Jabatan<v-icon class="ml-2" color="primary"
                >add_circle</v-icon
              ></v-btn
            >
            <v-spacer />
            <v-btn
              text
              class="text-capitalize caption headline-color"
              color="primary"
              @click="visibleImport = true"
              >Import<v-icon class="ml-2" color="primary"
                >file_download</v-icon
              ></v-btn
            >
            <v-btn
              text
              class="text-capitalize caption headline-color"
              color="primary"
              @click="visibleExport = true"
              :loading="loadingExport"
              >Export<v-icon class="ml-2" color="primary"
                >file_upload</v-icon
              ></v-btn
            >
          </v-card-actions>
          <v-row class="mx-2">
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units"
                v-model="unit"
                :loading="loadingUnit"
                placeholder="Unit Utama"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_utama_id"
                clearable
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units2"
                v-model="unit2"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                clearable
                @change="changeUnit(2)"
                @click:clear="changeUnit(2)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units3"
                v-model="unit3"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_3_id"
                clearable
                @change="changeUnit(3)"
                @click:clear="changeUnit(3)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units4"
                v-model="unit4"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_4_id"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col cols="12" xl="3" lg="3" md="6" sm="12" xs="12" class="my-0">
              <v-select
                :items="[
                  { label: 'Ada', value: 'fj' },
                  { label: 'Tidak', value: 'nfj' }
                ]"
                v-model="type"
                placeholder="Formasi Jabatan"
                outlined
                dense
                hide-details
                item-text="label"
                item-value="value"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="12" xs="12" class="my-0">
              <v-autocomplete
                :items="categories"
                v-model="category"
                :loading="loadingCategory"
                placeholder="Kategori"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="code"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="12" xs="12" class="my-0">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="lists"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.unit`]="{ item }">
              <div class="py-1" style="min-width: 150px">
                <p class="mb-0">
                  <b>Unit Utama: </b>{{ item.nama_unit_utama }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 2: </b>{{ item.nama_unit_kerja_2 }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 3: </b>{{ item.nama_unit_kerja_3 }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 4: </b>{{ item.nama_unit_kerja_4 }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon small color="primary" @click="handleEdit(item)"
                ><v-icon small>edit</v-icon></v-btn
              >
              <v-btn icon small color="primary" @click="handleDelete(item)"
                ><v-icon small>delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="850">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          {{ isEdit ? "Ubah" : "Buat" }} Formasi Jabatan
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row v-if="isEdit">
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Kode#</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="form.formasi_jabatan_id"
                disabled
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card outlined class="pa-3 mt-2">
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Unit Utama</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-select
                  :items="unitList"
                  v-model="form.unit_utama"
                  :loading="loadingUnit"
                  outlined
                  dense
                  item-text="singkatan"
                  item-value="unit_utama_id"
                  @change="changeUnitList(1)"
                  @click:clear="changeUnitList(1)"
                  :rules="[v => !!v || 'Unit utama harus diisi']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Unit Kerja</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-select
                  :items="unit2List"
                  v-model="form.unit_kerja_2"
                  :loading="loadingUnit"
                  outlined
                  dense
                  item-text="singkatan"
                  item-value="unit_kerja_2_id"
                  @change="changeUnitList(2)"
                  @click:clear="changeUnitList(2)"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-1"></v-col>
              <v-col cols="9" class="py-1">
                <v-select
                  :items="unit3List"
                  v-model="form.unit_kerja_3"
                  :loading="loadingUnit"
                  outlined
                  dense
                  item-text="singkatan"
                  item-value="unit_kerja_3_id"
                  @change="changeUnitList(3)"
                  @click:clear="changeUnitList(3)"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-1"></v-col>
              <v-col cols="9" class="py-1">
                <v-select
                  :items="unit4List"
                  v-model="form.unit_kerja_4"
                  :loading="loadingUnit"
                  outlined
                  dense
                  item-text="singkatan"
                  item-value="unit_kerja_4_id"
                  @change="changeUnitList(4)"
                  @click:clear="changeUnitList(4)"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
          <v-card outlined class="pa-3 mt-2">
            <v-subheader class="caption headline-color font-weight-regular"
              >Jabatan</v-subheader
            >
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Kategori</v-subheader
                >
              </v-col>
              <v-col cols="3" class="py-1">
                <v-select
                  :items="categories"
                  :loading="loadingCategory"
                  v-model="form.kategori"
                  outlined
                  dense
                  item-text="name"
                  item-value="code"
                  :rules="[v => !!v || 'Kategori harus diisi']"
                  @change="changeKategori"
                ></v-select>
              </v-col>
              <v-col cols="4" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Pimpinan Unit Kerja</v-subheader
                >
              </v-col>
              <v-col cols="2" class="py-1">
                <v-select
                  :items="[
                    { label: 'Ya', value: '1' },
                    { label: 'Tidak', value: '0' }
                  ]"
                  v-model="form.pejabat_unit_kerja"
                  outlined
                  dense
                  item-text="label"
                  item-value="value"
                  :disabled="!form.pimpinan_choose"
                  :rules="
                    form.pimpinan_choose
                      ? [v => !!v || 'Pimpinan harus diisi']
                      : []
                  "
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Nama Jabatan</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-select
                  :items="positions"
                  :loading="loadingPosition"
                  v-model="form.jabatan_id"
                  outlined
                  dense
                  item-text="nama_jabatan"
                  item-value="jabatan_id"
                  :rules="[v => !!v || 'Jabatan harus diisi']"
                  @change="changePosition"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Kebutuhan(K)</v-subheader
                >
              </v-col>
              <v-col cols="3" class="py-1">
                <v-text-field
                  v-model="form.quota"
                  outlined
                  dense
                  suffix="Pegawai"
                  :rules="[v => !!v || 'Kebutuhan harus diisi']"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Persediaan(B)</v-subheader
                >
              </v-col>
              <v-col cols="3" class="py-1">
                <v-text-field
                  v-model="form.persediaan"
                  outlined
                  dense
                  suffix="Pegawai"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Kelas Jabatan (Grade)</v-subheader
                >
              </v-col>
              <v-col cols="2" class="py-1">
                <v-text-field
                  v-model="form.kelas_jabatan"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
          <v-row class="mt-2">
            <v-col cols="2" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Atasan Langsung</v-subheader
              >
            </v-col>
            <v-col cols="10" class="py-1">
              <v-select
                :items="heads"
                :loading="loadingHead"
                v-model="form.jabatan_atasan_id"
                outlined
                dense
                item-text="nama_jabatan"
                item-value="jabatan_id"
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visibleImport" persistent max-width="600">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visibleImport = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Import Formasi Jabatan
        </v-card-text>
        <v-divider class="mb-4" />
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Unit Utama</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-select
              :items="unitList"
              v-model="importUnitUtama"
              :loading="loadingUnit"
              outlined
              dense
              item-text="singkatan"
              item-value="unit_utama_id"
            ></v-select>
          </v-col>
        </v-row>
        <FileDraggable
          ref="fileDraggable"
          :accept="this.accept"
          @on-change="handleOnChange"
        />
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visibleImport = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            :loading="loadingImport"
            @click="importFormasiJabatan"
            >Import</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visibleExport" persistent max-width="600">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visibleExport = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Export Formasi Jabatan
        </v-card-text>
        <v-divider class="mb-4" />
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Unit Utama</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-select
              :items="unitList"
              v-model="exportUnitUtama"
              :loading="loadingUnit"
              outlined
              dense
              item-text="singkatan"
              item-value="unit_utama_id"
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visibleImport = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            :loading="loadingImport"
            @click="exportFormasiJabatan"
            >Export</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ACCEPT_IMPORT } from "@/common/constant";
import DataUtamaService from "@/services/resources/data.utama.service";
const FileDraggable = () => import("@/components/File/Draggable");

export default {
  components: {
    FileDraggable
  },
  data() {
    return {
      accept: [ACCEPT_IMPORT.EXCEL],
      visible: false,
      visibleImport: false,
      visibleExport: false,
      valid: false,
      loading: false,
      formLoading: false,
      loadingImport: false,
      loadingExport: false,
      loadingUnit: false,
      loadingHead: false,
      loadingCategory: false,
      loadingPosition: false,
      type: null,
      category: null,
      unit: null,
      unit2: null,
      unit3: null,
      unit4: null,
      units: [],
      units2: [],
      units3: [],
      units4: [],
      unitList: [],
      unit2List: [],
      unit3List: [],
      unit4List: [],
      importUnitUtama: null,
      exportUnitUtama: null,
      categories: [],
      positions: [],
      heads: [],
      search: null,
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false
        },
        {
          text: "Jabatan",
          value: "nama_jabatan",
          sortable: false
        },
        {
          text: "Kategori",
          value: "kategori",
          sortable: false
        },
        {
          text: "Unit Kerja",
          value: "unit",
          sortable: false
        },
        {
          text: "(B)",
          value: "persediaan",
          sortable: false
        },
        {
          text: "(K)",
          value: "quota",
          sortable: false
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      lists: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["nama_jabatan"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      fileImport: null,
      form: {
        formasi_jabatan_id: null,
        unit_utama: null,
        unit_kerja_2: null,
        unit_kerja_3: null,
        unit_kerja_4: null,
        kategori: null,
        pejabat_unit_kerja: "0",
        pimpinan_choose: false,
        jabatan_id: null,
        quota: null,
        persediaan: null,
        kelas_jabatan: null,
        jabatan_atasan_id: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getFormasiJabatanList);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getFormasiJabatanList);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
    type(val) {
      if (val == 0) {
        this.search = null;
      } else if (val == 1) {
        this.unit = null;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      }
    },
    quotaProperty() {
      this.fetchListDebounce(this.getFormasiJabatanPersediaan);
    }
  },
  computed: {
    paginationProperty() {
      return [
        this.search,
        this.category,
        this.type,
        this.unit_kerja_2_id
      ].join();
    },
    quotaProperty() {
      return [
        this.form.jabatan_id,
        this.form.unit_utama,
        this.form.unit_kerja_2,
        this.form.unit_kerja_3,
        this.form.unit_kerja_4
      ];
    },
    isEdit() {
      return Boolean(this.form.formasi_jabatan_id);
    }
  },
  methods: {
    handleEdit(item) {
      this.visible = true;
      this.getDetailFormasiJabatan(item.formasi_jabatan_id);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              formasi_jabatan_id: item.formasi_jabatan_id
            };
            this.deleteFormasiJabatan(payload);
          }
        }
      });
    },
    changeKategori(val) {
      this.form.jabatan_id = null;
      this.form.pejabat_unit_kerja = "0";
      this.form.pimpinan_choose =
        this.categories.find(d => d.code === this.form.kategori)
          ?.pimpinan_choose || false;
      this.getJabatanByKategori(val);
    },
    changePosition(val) {
      this.form.kelas_jabatan = this.positions.find(
        d => d.jabatan_id === val
      )?.kelas_jabatan;
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    changeUnitList(type = 2) {
      if (type == 1) {
        this.unit2List = this.unitList.find(
          d => d.unit_utama_id == this.form.unit_utama
        )?.unit_kerja2;
        this.form.unit_kerja_2 = null;
        this.form.unit_kerja_3 = null;
        this.form.unit_kerja_4 = null;
      } else if (type == 2) {
        this.unit3List = this.unit2List.find(
          d => d.unit_kerja_2_id == this.form.unit_kerja_2
        )?.unit_kerja3;
        this.form.unit_kerja_3 = null;
        this.form.unit_kerja_4 = null;
      } else if (type == 3) {
        this.unit4List = this.unit3List.find(
          d => d.unit_kerja_3_id == this.form.unit_kerja_3
        )?.unit_kerja4;
        this.form.unit_kerja_4 = null;
      }
    },
    populateUnit() {
      this.unit2List = this.unitList.find(
        d => d.unit_utama_id == this.form.unit_utama
      )?.unit_kerja2;
      this.unit3List = this.unit2List.find(
        d => d.unit_kerja_2_id == this.form.unit_kerja_2
      )?.unit_kerja3;
      this.unit4List = this.unit3List.find(
        d => d.unit_kerja_3_id == this.form.unit_kerja_3
      )?.unit_kerja4;
    },
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("action", this.isEdit ? 2 : 1);
        formData.append(
          "formasi_jabatan_id",
          this.form.formasi_jabatan_id || 0
        );
        formData.append("unit_utama_id", this.form.unit_utama);
        formData.append("unit_kerja_2_id", this.form.unit_kerja_2);
        formData.append("unit_kerja_3_id", this.form.unit_kerja_3);
        formData.append("unit_kerja_4_id", this.form.unit_kerja_4 || "");
        formData.append("jabatan_id", this.form.jabatan_id);
        formData.append("pejabat_unit_kerja", this.form.pejabat_unit_kerja);
        formData.append("quota", this.form.quota);
        formData.append("jabatan_atasan_id", this.form.jabatan_atasan_id);
        this.saveFormasiJabatan(formData);
      }
    },
    handleOnChange(val) {
      this.fileImport = val;
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
              this.unitList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListKategori() {
      try {
        this.loadingCategory = true;
        await DataUtamaService.getJabatanListKategori()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.categories = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingCategory = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getJabatanByKategori(code) {
      try {
        this.loadingPosition = true;
        await DataUtamaService.getJabatanByKategori({
          kategori: code
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.positions = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingPosition = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getFormasiJabatanPersediaan() {
      try {
        this.loadingPosition = true;
        await DataUtamaService.getFormasiJabatanPersediaan({
          jabatan_id: this.form.jabatan_id,
          unit_utama_id: this.form.unit_utama,
          unit_kerja_2_id: this.form.unit_kerja_2,
          unit_kerja_3_id: this.form.unit_kerja_3,
          unit_kerja_4_id: this.form.unit_kerja_4
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form.persediaan = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingPosition = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getHeadByKategori() {
      try {
        this.loadingHead = true;
        await DataUtamaService.getJabatanByKategori({
          kategori: "Struktural"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.heads = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingHead = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getFormasiJabatanList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await DataUtamaService.getFormasiJabatanList({
          filter: {
            search: this.search,
            kategori: this.category ? [this.category] : []
          },
          type: this.type,
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            let { list, pageTotal, countTotal } = data;
            if (status) {
              let lists = list;
              lists.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.lists = lists;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveFormasiJabatan(data) {
      try {
        this.formLoading = true;
        await DataUtamaService.saveFormasiJabatan(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getFormasiJabatanList();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailFormasiJabatan(id) {
      try {
        this.loading = true;
        await DataUtamaService.getFormasiJabatanDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form = {
                ...this.form,
                formasi_jabatan_id: data.formasi_jabatan_id,
                jabatan_id: data.jabatan_id,
                unit_utama: data.unit_utama_id,
                unit_kerja_2: data.unit_kerja_2_id,
                unit_kerja_3: data.unit_kerja_3_id,
                unit_kerja_4: data.unit_kerja_4_id,
                kategori: data.kategori,
                pejabat_unit_kerja: String(data.pejabat_unit_kerja),
                pimpinan_choose: Boolean(data.pejabat_unit_kerja),
                quota: data.quota,
                persediaan: data.persediaan,
                kelas_jabatan: data.kelas_jabatan,
                jabatan_atasan_id: data.jabatan_atasan_id
              };
              this.getJabatanByKategori(data.kategori);
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteFormasiJabatan(payload) {
      try {
        this.formLoading = true;
        await DataUtamaService.deleteFormasiJabatan(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getFormasiJabatanList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async exportFormasiJabatan() {
      try {
        this.loadingExport = true;
        await DataUtamaService.exportFormasiJabatan({
          unit_utama_id: this.exportUnitUtama
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.downloadTemplate(
                data,
                `export_formasi_jabatan_${this.$moment(new Date()).format(
                  "YYYY-MM-DD"
                )}.xlsx`
              );
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingExport = false));
      } catch (err) {
        console.error(err);
      }
    },
    async importFormasiJabatan() {
      try {
        this.loadingImport = true;
        let formData = new FormData();
        formData.append("unit_utama_id", this.importUnitUtama);
        formData.append("file", this.fileImport);
        await DataUtamaService.importFormasiJabatan(formData)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingImport = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
    this.getListKategori();
    this.getHeadByKategori();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
